import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Divider,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Button,
  Snackbar,
  MenuItem,
} from "@mui/material";
import Alert from '@mui/material/Alert';

import Breadcrumb from "component/Breadcrumb";
import { gridSpacing } from "config.js";
import axios from "axios";
import * as yup from "yup"; // Import yup for validation
import '../../assets/css/style.css';

const AddRetailer = () => {
  const [formData, setFormData] = useState({
    retailerName: "",
    Email: "",
    Password: "",
    GSTIN: "",
    state: "",
    pincode: "",
    mobileNo: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false); 
  const token = localStorage.getItem("token");

  // Define a validation schema using yup
  const validationSchema = yup.object().shape({
    retailerName: yup.string().required("Retailer Name is required"),
    Email: yup.string().email("Invalid email").required("Email is required"),
    Password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    state: yup.string().required("State is required"),
    pincode: yup.string().required("Pincode is required").max(6),
    mobileNo: yup.string().matches(/^[6-9]\d{9}$/, "Please Enter Correct Mobile Number").min(10, "Mobile No. must be exactly 10 digits").max(10, "Mobile No. must be exactly 10 digits").required("Mobile No. is required"),
    GSTIN: yup.string().nullable(), // GSTIN can be optional
  });

  const senddata = () => {
    setFormSubmitted(true);

    // Validate form data against the schema
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        axios
          .post(
            "https://coa.cpdtechnologies.in/api/user/retailers",
            {
              Name: formData.retailerName,
              Email: formData.Email,
              Password: formData.Password,
              GSTIN: formData.GSTIN,
              State: formData.state,
              Pincode: formData.pincode,
              MobileNo: formData.mobileNo,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(function (response) {
            setSuccessMessage("Retailer created successfully.");
            setErrorMessage(""); 
            setFormSubmitted(false);
            setErrors({});
            setFormData({
              retailerName: "",
              Email: "",
              Password: "",
              GSTIN: "",
              state: "",
              pincode: "",
              mobileNo: "",
            });
          })
          .catch(function (error) {
            if (error.response && error.response.data && error.response.data.message) {
              setErrorMessage(error.response.data.message);
            } else {
               setErrorMessage(error.response.data.errors[0].message);
              console.log(error.response.data.errors[0].message);
            }
            setSuccessMessage(""); 
          });
      })
      .catch((validationErrors) => {
        const formErrors = {};
        validationErrors.inner.forEach((error) => {
          formErrors[error.path] = error.message;
        });
        setErrors(formErrors);
        setErrorMessage("Please correct the highlighted Fields");
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",  // Clear the error message for the field being updated
    });
  };

  const states = [
    { value: "Andaman and Nicobar Islands", label: "Andaman and Nicobar Islands" },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Dadra and Nagar Haveli and Daman and Diu", label: "Dadra and Nagar Haveli and Daman and Diu" },
    { value: "Delhi", label: "Delhi" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Ladakh", label: "Ladakh" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" }
  ];

  return (
    <>
      <Breadcrumb title="Add Retailer">
        <Typography
          component={Link}
          to="/"
          variant="subtitle2"
          color="inherit"
          className="link-breadcrumb"
        >
          Home
        </Typography>
        <Typography
          variant="subtitle2"
          color="primary"
          className="link-breadcrumb"
        >
          Add Retailer
        </Typography>
      </Breadcrumb>
      <Grid container justifyContent="center" spacing={gridSpacing}>
        <Grid item lg={8} sm={6} xs={12}>
          <Card>
            <CardHeader
              title={
                <Typography component="div" className="card-header">
                  Add Retailer
                </Typography>
              }
            />
            <Divider />
            <CardContent>
              <form>
                <Grid container key="form-grid" fullWidth gap={3}>
                  <TextField
                    xs={12}
                    sx={{ mt: 2, width: '100%' }}
                    id="outlined-basic"
                    label="Retailer Name"
                    variant="outlined"
                    value={formData.retailerName}
                    onChange={handleInputChange}
                    name="retailerName"
                    required
                    error={!!errors.retailerName}
                    helperText={errors.retailerName}
                  />
                  <TextField
                    lg={12}
                    sx={{ mt: 2, width: '100%'}}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={formData.Email}
                    onChange={handleInputChange}
                    name="Email"
                    type="email"
                    required
                    error={!!errors.Email}
                    helperText={errors.Email}
                  />
                  <TextField
                    lg={12}
                    sx={{ mt: 2 , width: '100%'}}
                    id="outlined-basic"
                    label="Mobile No."
                    variant="outlined"
                    value={formData.mobileNo}
                    onChange={handleInputChange}
                    name="mobileNo"
                    required
                    error={!!errors.mobileNo}
                    helperText={errors.mobileNo}
                  />
                  <TextField
                    lg={12}
                    sx={{ mt: 2, width: '100%' }}
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={formData.Password}
                    onChange={handleInputChange}
                    name="Password"
                    required
                    error={!!errors.Password}
                    helperText={errors.Password}
                  />
                  <TextField
                    select
                    lg={4}
                    xs={12}
                    className="retail-text"
                    sx={{ mt: 2, minWidth: 150 }}
                    id="outlined-basic"
                    label="State"
                    variant="outlined"
                    value={formData.state}
                    onChange={handleInputChange}
                    name="state"
                    required
                    error={!!errors.state}
                    helperText={errors.state}
                  >
                    {states.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    lg={12}
                    xs={12}
                    className="retail-text"
                    sx={{ mt: 2, minWidth: 150 }}
                    id="outlined-basic"
                    label="Pincode"
                    variant="outlined"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    name="pincode"
                    required
                    error={!!errors.pincode}
                    helperText={errors.pincode}
                  />
                  <TextField
                    lg={12}
                    xs={12}
                    className="retail-text"
                    sx={{ mt: 2, minWidth: 150 }}
                    id="outlined-basic"
                    label="GSTIN"
                    variant="outlined"
                    value={formData.GSTIN}
                    onChange={handleInputChange}
                    name="GSTIN"
                    error={!!errors.GSTIN}
                    helperText={errors.GSTIN}
                  />
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={senddata}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddRetailer;
