import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, IconButton, Typography } from '@mui/material';

// project import
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { drawerWidth } from 'config.js';

// assets
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import logo from 'assets/images/logo.png';
import axios from 'axios';
import { useSelector } from 'react-redux';
// ==============================|| HEADER ||============================== //

const Header = ({ drawerToggle }) => {
  const [user, setUser] = useState(null);
  
  const theme = useTheme();
  const token = localStorage.getItem("token")
  useEffect(() => {
   
    axios
      .get("https://coa.cpdtechnologies.in/api/user/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setUser(response.data.data);
      })
      .catch(function(err){
        console.log(err)
      })
  },[]);
  return (
    <>
      <Box width={drawerWidth}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Grid item>
              <Box align='center' mt={0.5}>
                {/* <img src={logo} alt="Logo" /> */}
                <Typography  variant='h2' color='white' >JORDER</Typography>
              </Box>
            </Grid>
          </Box>
          <Grid item>
            <IconButton
              edge="start"
              sx={{ mr: theme.spacing(1.25) }}
              color="inherit"
              aria-label="open drawer"
              onClick={drawerToggle}
              size="large"
            >
              <MenuTwoToneIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {/* <SearchSection theme="light" />
      <NotificationSection /> */}
      {user && (
        <Box sx={{whiteSpace:'nowrap'}}>
          <p>{user.Name}</p> {/* Display user name or other user info here */}
        </Box>
      )}
      <ProfileSection />
    </>
  );
};

export default Header;
